<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="please choose" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>UniLive Store Opening Agreement</h2>
		<p>The UniLive Store Agreement is Pix Technology PTE. LTD.
			The legally binding electronic agreement between LTD. (hereinafter referred to as "our party") and you (who are a
			natural person, legal person or other organization) regulating the rights and obligations of both parties,
			hereinafter referred to as "this agreement". By selecting "I agree" or clicking the "I have read and complied with
			this agreement" button, you indicate that you have carefully read, fully understood, and fully accepted all the
			terms of this agreement without reservation.
		</p>
		<h3>Article 1 Purpose of the Agreement</h3>
		<p>This agreement aims to clarify the rights and obligations between merchants and the UniLive platform, regulate
			the business behavior of merchants, safeguard consumer rights, promote the healthy development of the UniLive
			platform, and ensure the legitimate rights and interests of all parties in transactions.
		</p>
		<h3>Article 2 Definition</h3>
		<p>1. UniLive: refers to the operator of this application and its related services, providing a platform for online
			transactions and service support</p>
		<p>
			2. Merchant: refers to individuals or businesses registered and conducting commercial activities on UniLive,
			including but not limited to individual businesses, companies, and other legal entities.
		</p>
		<p>
			3. Consumer: Refers to users who purchase goods or services on UniLive.
		</p>
		<h3>Article 3 Merchant Registration</h3>
		<p>
			1. Registration requirements: Merchants must provide truthful and accurate personal or business information on
			UniLive, including name, contact information, business license, tax registration certificate, etc. Merchants
			should ensure timely updates of registration information, and if there are any changes, they must be updated
			within 48 hours.
		</p>
		<p>2. Review process: UniLive will review the information submitted by merchants, and the review time is usually 3-5
			working days. After the review is approved, the merchant will receive a confirmation notification and the store
			opening function will be officially activated.
		</p>
		<p>3. Store setup: Merchants need to set up their store on UniLive, including store name, logo, introduction,
			product classification, etc., to ensure that the information complies with platform regulations.
		</p>
		<h3>Article 4 Rights and Obligations of Merchants</h3>
		<p>
			1. Publish product information and sell on UniLive, and enjoy various services provided by the platform.
		</p>
		<p>2. Participate in UniLive's promotional activities and marketing campaigns to increase exposure</p>
		<p>3. Obtain sales data, user feedback, and market analysis reports to optimize business strategies</p>
		<p>4. Product compliance: Ensure the legality, authenticity, and compliance of the products sold, and prohibit the
			sale of counterfeit and inferior products</p>
		<p>5. Information update: Regularly update product information to ensure accurate and clear pricing, inventory, and
			delivery information</p>
		<p>6. Comply with platform rules: Adhere to UniLive's various management regulations and operating standards,
			participate in platform training, and enhance business capabilities</p>
		<h3>Article 5 Rights and Obligations of UniLive</h3>
		<p>1. Review and manage the information released by merchants to ensure its compliance</p>
		<p>2. Take corresponding measures against merchants who violate the agreement, including but not limited to warning,
			suspending or terminating their stores, until legal action is taken</p>
		<p>3. According to market conditions and changes in laws and regulations, regularly update platform policies, and
			merchants should promptly understand and comply with them</p>
		<p>4. Provide a secure and stable trading platform to ensure the transaction security of merchants and consumers,
			and prevent fraud and other illegal activities</p>
		<p>5. Timely address merchant issues and feedback, provide necessary technical support and training</p>
		<p>6. Regularly provide business data analysis, market trend reports, and promotion recommendations to help
			businesses improve their performance</p>
		<h3>Article 6 Liability for Breach of Contract</h3>
		<p>
			1. If a merchant violates any terms of this agreement, UniLive has the right to demand that the merchant rectify
			the situation and take corresponding punitive measures depending on the severity of the circumstances, including
			but not limited to lowering the store level, restricting product listings, terminating services, etc.
		</p>
		<p>2. Consumers' complaints caused by the merchant shall bear corresponding legal and compensation responsibilities
			</p>
		<p>3. If UniLive fails to provide the services as agreed in the agreement, the merchant may request UniLive to
			compensate for the losses, and the specific compensation amount shall be determined through negotiation between
			both parties</p>
		<h3>Article 7: Amendment and Termination of the Agreement</h3>
		<p>1. Any modification or change to this agreement must be mutually agreed upon by both parties before it can be
			terminated</p>
		<p>2. UniLive has the right to regularly revise the agreement based on laws, regulations, market changes, and other
			factors, and notify merchants in a timely manner</p>
		<p>3. If the merchant wishes to terminate this agreement, they shall notify UniLive in writing 7 days in advance and
			handle any unfinished orders and consumer complaints properly before termination</p>
		<p>4. UniLive has the right to immediately terminate this agreement upon discovering serious breaches by merchants
			without prior notice</p>
		<h3>Article 8 Other Provisions</h3>
		<p>1. Confidentiality clause: Both parties shall keep confidential any trade secrets and other information obtained
			during the performance of the agreement, and shall not disclose them to any third party.
		</p>
		<p>2. Force Majeure: If the agreement cannot be fulfilled due to force majeure factors (such as natural disasters,
			wars, policy changes, etc.), both parties shall not be liable for breach of contract.
		</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/StoreOpeningAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/StoreOpeningAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/StoreOpeningAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/StoreOpeningAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/StoreOpeningAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/StoreOpeningAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/StoreOpeningAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/StoreOpeningAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/StoreOpeningAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/StoreOpeningAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/StoreOpeningAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/StoreOpeningAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/StoreOpeningAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/StoreOpeningAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>